export const CINCH_COC_AUTO_DISCLOSURES_FLAG = 'cinch-coc-auto-disclosures';
export const CINCH_INITIAL_DISCLOSURES_FLAG = 'cinch-initial-disclosures';
export const CINCH_COPY_LOAN_FLAG = 'cinch-copy-loan';
export const CINCH_NQM_PRICER_FLAG = 'cinch-nqm-loanpricer';
export const CINCH_EXCLUDED_FIELDS = 'cinch-excluded-fields';
export const CINCHSETTING_EXTERNAL_LINKS = 'external-links';
export const CINCH_DU_LP_SERVICE_FLAG = 'cinch-du-lp-service';
export const CINCH_NOI_FLAG = 'cinch-noi';
export const CINCH_NEW_PREAPPROVAL = 'cinch-preapproval';
export const CINCH_CUSTOM_EMAIL_SIGNATURE = 'cinch-custom-email-signature';
export const CINCH_SUMMARY_AND_TOOLS = 'cinch-summary-and-tools';
export const CINCH_FRAUDGUARD = 'cinch-fraudguard';
export const CINCH_ACCOUNTING_FLAG = 'cinch-accounting';
export const CINCH_OPTIMALBLUE_SERVICE_FLAG = 'cinch-optimalblue-service';
export const CINCH_STATEMENT_OF_DENIAL_FLAG = 'cinch-statement-of-denial';
export const CINCH_SIGNNOW_SERVICE_FLAG = 'cinch-signnow-service';
export const CINCH_CONSENT_SERVICE_FLAG = 'cinch-consent-service';
export const CINCH_USE_ECONSENT_FLAG = 'cinch-use-econsent';
export const CINCH_FNF_RUN_ERNST = 'cinch-fnf-run-ernst';
export const CINCH_CLOSING_DISCLOSURES_FLAG = 'cinch-closing-disclosures';
export const CINCH_COPY_DOCUMENTS = 'cinch-copy-documents';
export const CINCH_DRAW_REQUEST = 'cinch-draw-request';
